._7lECra_footer {
  flex-direction: row-reverse;
  display: flex;
}

.ZLzsgq_spinner {
  aspect-ratio: 1;
  text-align: center;
  width: 150px;
  margin: 0 auto;
  animation: 1s steps(8, end) infinite ZLzsgq_load;
  display: grid;
  -webkit-mask: conic-gradient(from 22deg, #0003, #000);
  mask: conic-gradient(from 22deg, #0003, #000);
}

.ZLzsgq_spinner, .ZLzsgq_spinner:before {
  --_g: linear-gradient(#355e3b 0 0) 50%;
  background: var(--_g) / 34% 8% space no-repeat, var(--_g) / 8% 34% no-repeat space;
}

.ZLzsgq_spinner:before {
  content: "";
  transform: rotate(45deg);
}

@keyframes ZLzsgq_load {
  to {
    transform: rotate(1turn);
  }
}
/*# sourceMappingURL=index.7937d467.css.map */
